import React, { useState, useEffect } from 'react';
import TrainingPlanDetailsNotesModal from '../TrainingPlanDetailsNotesModal'
import moment from 'moment';
import {
  Button, Row, Col, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Table,
  UncontrolledCollapse, Card, CardBody, InputGroup, InputGroupAddon, Label
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import history from '~/services/history';
import UserActivitiesModal from '../../Users/show';
import { formatDuration } from '~/services/util';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { formatTrainingPlanRequestObjective, formatRequestKeepFitType } from '~/services/util'
import Pagination from "react-reactstrap-pagination";
import MAClose from '@material-ui/icons/Close';
import Loader from '../../../components/Loader/Loader'

function formatUserTrainingLevel(value) {
  if (
    value === 'tenho corrido mais de 2h por semana' ||
    value === 'estou entre 2 e 6 meses sem correr' ||
    value === '+ 1h por semana' ||
    value === '+ 2h por semana' ||
    value === '+ 3h por semana' ||
    value === '+ 4h por semana' ||
    value === '+ 3 atividades por semana' ||
    value === '16_a_20km' ||
    value === 'acima_de_20km'
  ) {
    return 3
  } else if (
    value === 'tenho corrido de 1h a 2h por semana' ||
    value === 'estou entre 6 meses e 1 ano sem correr' ||
    value === '30min a 1h total por semana' ||
    value === '1h a 2h total por semana' ||
    value === '1h30 a 3h total por semana' ||
    value === '3h a 4h total por semana' ||
    value === '2 atividades por semana' ||
    value === '11_a_15km'
  ) {
    return 2
  } else if (
    value === 'tenho corrido muito pouco ou nada' ||
    value === 'nunca corri com regularidade' ||
    value === 'zero ou quase nada' ||
    value === '- 1h30 ou estou sem correr' ||
    value === '- 3h ou estou sem correr' ||
    value === 'zero ou 1 atividade por semana' ||
    value === '0_a_5km' ||
    value === '6_a_10km'
  ) {
    return 1
  }
}

function formatTrainingSessionTime(str) {
  switch (str) {
    case 'ate 30min':
      return 'Até 30 minutos'
    case 'ate 45min':
      return 'Até 45 minutos'
    case 'ate 1h':
      return 'Até 1 hora'
    default:
      return ''
  }
}

export default function TrainingPlanDetailsModal(props) {
  const profile = useSelector(state => state.user.profile);
  const [show, setShow] = useState(props.open);
  const [showCoachNotesModal, setShowCoachNotesModal] = useState(false)
  const [athlete, setAthlete] = useState(props.athlete);
  const [company, setCompany] = useState();
  const [trainingPlanRequest, setTrainingPlanRequest] = useState(props.tpRequest);
  const [zones, setZones] = useState([]);
  const [showUserActivitiesModal, setShowUserActivitiesModal] = useState(false);
  const [trainingPlanDate, setTrainingPlanDate] = useState();
  const [plansToAssociate, setPlansToAssociate] = useState([]);
  const [athletePlans, setAthletePlans] = useState([]);
  const [searchTrainingPlanQuery, setSearchTrainingPlanQuery] = useState();
  const [trainingPlansList, setTrainingPlansList] = useState([]);
  const [coachsList, setCoachsList] = useState([]);
  const [showDDCoach, setShowDDCoach] = useState(false);
  const [currentCoach, setCurrentCoach] = useState({ name: 'Responsável', id: -1 });
  const [statusList, setStatusList] = useState([{ id: 'OPEN', name: 'Aberto' }, { id: 'FINISHED', name: 'Concluído' }, { id: 'CANCELED', name: 'Cancelado' }, { id: 'CREATED', name: 'Novo' }, { id: 'PENDING', name: 'Pendente' }]);
  const [showDDStatus, setShowDDStatus] = useState(false);
  const [currentStatus, setCurrentStatus] = useState({ name: 'Status', id: -1 });
  const [daysWeeklyWorkout, setDaysWeeklyWorkout] = useState('--');
  const [userTrainingPlansTotal, setUserTrainingPlansTotal] = useState(0)
  const [userTrainingPlansPerPage, setUserTrainingPlansPerPage] = useState(5)
  const [userTrainingPlansSelectedPage, setUserTrainingPlansSelectedPage] = useState(1)
  const [internalNotes, setInternalNotes] = useState(trainingPlanRequest.internal_notes)
  const [loading, setLoading] = useState(false)
  const [ownerCoach, setOwnerCoach] = useState();

  useEffect(() => {
    Promise.all([
      api.get(`/training_plans?userId=${trainingPlanRequest.user_id}&page=1`),
      api.get(`/user_zones?user_id=${trainingPlanRequest.user_id}&limit=3`),
      api.get(`/users/${trainingPlanRequest.user_id}`),
      api.get(`/user_coaches`)])
      .then(async ([applyTPToUser, zonesResp, athleteResp, coachsResp]) => {
        setAthlete(athleteResp.data)
        if (athleteResp.data.companies && athleteResp.data.companies.length > 0) {
          setCompany(athleteResp.data.companies[0])
        }

        setCoachsList(coachsResp.data);
        if (athleteResp.data) {
          const cid = athleteResp.data.coach_id;
          if (cid) {
            const owner = coachsResp.data.find(c => c.id === cid);
            setOwnerCoach(owner);
          }
        }

        if (trainingPlanRequest.coach) {
          setCurrentCoach(trainingPlanRequest.coach)
        }

        const curStatus = statusList.filter(status => status.id === trainingPlanRequest.status)
        setCurrentStatus(curStatus[0])

        if (zonesResp.data && zonesResp.data.length > 0) {
          setZones(zonesResp.data)
        }

        setAthletePlans(applyTPToUser.data.data)
        setUserTrainingPlansTotal(applyTPToUser.data.total)
        setUserTrainingPlansPerPage(applyTPToUser.data.perPage)
        setUserTrainingPlansSelectedPage(applyTPToUser.data.page)
      });

  }, [statusList, trainingPlanRequest])

  useEffect(() => {
    if (trainingPlanRequest?.days_weekly_workout) {
      const daysArr = trainingPlanRequest.days_weekly_workout.split(',')
      const daysResult = []

      daysArr.forEach(day => {
        switch (day) {
          case '1':
            daysResult.push('Segunda')
            break;
          case '2':
            daysResult.push('Terça')
            break;
          case '3':
            daysResult.push('Quarta')
            break;
          case '4':
            daysResult.push('Quinta')
            break;
          case '5':
            daysResult.push('Sexta')
            break;
          case '6':
            daysResult.push('Sábado')
            break;
          case '0':
            daysResult.push('Domingo')
            break;

          default:
            daysResult.push('--')
            break;
        }
      });

      let daysStr = ''
      daysResult.forEach((dStr, dIdx) => {
        daysStr += dIdx === 0 ? dStr : ` / ${dStr}`
      });

      setDaysWeeklyWorkout(daysStr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingPlanRequest.days_weekly_workout])

  const updateTrainingPlans = async (selectedPage = null) => {
    Promise.all([api.get(`/training_plans?userId=${trainingPlanRequest.user_id}&page=${selectedPage || 1}`)])
      .then(async ([applyTPToUser]) => {
        setAthletePlans(applyTPToUser.data.data)
        setUserTrainingPlansTotal(applyTPToUser.data.total)
        setUserTrainingPlansPerPage(applyTPToUser.data.perPage)
        setUserTrainingPlansSelectedPage(applyTPToUser.data.page)
      });
  }

  const deassociate = async (planID) => {
    try {
      var result = window.confirm("Tem certeza que deseja remover esse plano do usuário?");
      if (result) {
        const resp = await api.post(`/training_plans/${planID}/deassociate`, {})

        if (resp.status === 200) {
          toast.success('Plano de treino desaplicado com sucesso')
        } else {
          toast.warn('Erro ao desaplicar plano de treino')
        }

        updateTrainingPlans()
      }
    } catch (error) {
      toast.error('Erro ao desaplicar plano de treino')
    }
  }

  const handleUserDetailModal = async () => {
    setShowUserActivitiesModal(!showUserActivitiesModal);
  }

  const searchTrainingPlans = async () => {
    const { data } = await api.get(`training_plans?searchQuery=${searchTrainingPlanQuery}`)
    setTrainingPlansList(data)
  }

  const selectUser = async (elem) => {
    var index = plansToAssociate.indexOf(elem);

    if (index > -1) {
      plansToAssociate.splice(index, 1);
    } else {
      plansToAssociate.push(elem)
    }
    await setPlansToAssociate(plansToAssociate)
  }

  const associateTPToUsers = async () => {
    if (!plansToAssociate || plansToAssociate.length <= 0) {
      toast.error('Selecione ao menos um plano de treino para vincular ao atleta')
      return;
    }

    if (!trainingPlanDate) {
      toast.error('Selecione uma data de início')
      return;
    }

    try {
      const tpAssociate = {
        training_plan_id: plansToAssociate[0],
        coach_id: profile.id,
        users: [trainingPlanRequest.user_id],
        start_date: trainingPlanDate,
        training_plan_request_id: trainingPlanRequest.id
      }

      const response = await api.post(`/training_plans/associate`, tpAssociate)

      if (response.status === 200) {
        toast.success('Plano de Treino associado com sucesso ao usuário')
      } else {
        toast.warn('Erro ao associar o plano de treino ao usuário')
      }

      updateTrainingPlans()
    } catch (error) {
      toast.error('Aconteceu um erro ao associar o plano de treino ao usuário')
    }
  }

  const updateRequest = async ({ currentCoach, currentStatus, internalNotes, coachNotes, withEmail = false }) => {
    try {
      setLoading(true);

      const reqToUpdate = {}

      if (currentCoach?.id > 0) {
        reqToUpdate.coach_id = currentCoach?.id
      }

      if (currentStatus?.id !== -1) {
        reqToUpdate.status = currentStatus?.id
      }

      if (internalNotes?.length > 0) {
        reqToUpdate.internal_notes = internalNotes
      }

      if (coachNotes?.length > 0) {
        reqToUpdate.coach_notes = coachNotes
      }

      await api.put(`training_plan_requests/${trainingPlanRequest.id}`, reqToUpdate)

      setLoading(false);

      toast.success('Solicitação atualizada com sucesso')

      await props.loadTPRequests(1)

      if (withEmail) {
        setShowCoachNotesModal(false)
      }
    } catch (error) {
      setLoading(false);

      toast.error('Problema ao atualizar solicitação, tente atualizar a tela e refazer a operação.')
    }
  }

  const chooseCoach = (coach) => {
    setCurrentCoach(coach)
  }

  const chooseStatus = (status) => {
    setCurrentStatus(status)
  }

  const handleClick = () => {
    if (currentStatus?.id === 'FINISHED') {
      if (currentCoach?.id <= 0) {
        toast.warn('Selecione o treinador antes de concluir')
        return
      } else {
        setShowCoachNotesModal(true)
      }
    } else {
      toast.warn('Só é possível enviar o email quando o status é Concluído')
    }
  }

  const handleUpdate = () => {
    if (currentStatus?.id) {
      if (currentCoach?.id <= 0) {
        toast.warn('Selecione o treinador antes de concluir')
        return
      } else {
        updateRequest({ currentCoach, currentStatus, internalNotes })
      }
    }
  }

  return (
    <Modal isOpen={show} toggle={() => props.toggle(!show)} style={{ maxWidth: '1600px', width: '70%', maxHeight: '800px', height: '80%' }}>
      {loading && <Loader />}
      {trainingPlanRequest.type !== 'ALTERNATIVE' && <ModalHeader toggle={() => props.toggle(!show)}>Solicitação de treino de corrida ({trainingPlanRequest.id})</ModalHeader>}
      {trainingPlanRequest.type === 'ALTERNATIVE' && <ModalHeader toggle={() => props.toggle(!show)}>Solicitação de treino alternativo ({trainingPlanRequest.id})</ModalHeader>}
      <ModalBody>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <p style={{ fontWeight: 'bold' }}>Dados da solicitação</p>
                <Row>
                  <Col lg={6}><span style={{ fontWeight: '550' }}> Objetivo: <br /> <span style={{ fontWeight: '400' }}>{trainingPlanRequest.distance ? `${trainingPlanRequest.distance}km` : ''} {trainingPlanRequest.type === 'KEEP_FIT' ? formatRequestKeepFitType(trainingPlanRequest.trainingType) : formatTrainingPlanRequestObjective(trainingPlanRequest.distance_history)} {trainingPlanRequest.type === 'SLIMMING' || trainingPlanRequest.type === 'RETURN_INITIATION' || trainingPlanRequest.type === 'ALTERNATIVE' ? trainingPlanRequest.title : ''}</span></span></Col>
                  <Col lg={6}>
                    <span style={{ fontWeight: '550' }}>
                      Data/hora solicitado: <br />
                      <span style={{ fontWeight: '400' }}>
                        {moment(trainingPlanRequest.created_at).format('DD/MM/YYYY HH:mm:ss')}
                      </span>
                    </span>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col lg={6}><span style={{ fontWeight: '550', marginTop: 5 }}> Nível de volume: <span style={{ fontWeight: '400' }}>{trainingPlanRequest.type === 'KEEP_FIT' ? '--' : ''} {trainingPlanRequest.currennt_practice ? formatUserTrainingLevel(trainingPlanRequest.currennt_practice) : ''} {(trainingPlanRequest.type === 'SLIMMING' || trainingPlanRequest.type === 'RETURN_INITIATION') && trainingPlanRequest.distance_history ? formatUserTrainingLevel(trainingPlanRequest.distance_history) : ''}</span></span></Col>
                  {trainingPlanRequest.type !== 'ALTERNATIVE' && <Col lg={6}><span style={{ fontWeight: '550', marginTop: 5 }}> Total de semanas: <span style={{ fontWeight: '400' }}>{Math.round(moment(trainingPlanRequest.target_date).diff(trainingPlanRequest.start_at, 'weeks', true).toFixed(1))}</span></span></Col>}
                  {trainingPlanRequest.type === 'ALTERNATIVE' && <Col lg={6}><span style={{ fontWeight: '550', marginTop: 5 }}> Tempo disponível para treinar: <span style={{ fontWeight: '400' }}>{formatTrainingSessionTime(trainingPlanRequest.training_session_time)}</span></span></Col>}
                </Row>
                <br />
                <Row style={{ marginTop: 5 }}>
                  <Col lg={6}>
                    <span style={{ fontWeight: '550' }}>
                      Data de início dos treinos: <br />
                      <span style={{ fontWeight: '400' }}>
                        {trainingPlanRequest.version > 1 && moment(trainingPlanRequest.start_at).format('DD/MM/YYYY')}
                        {trainingPlanRequest.version === 1 && moment(trainingPlanRequest.start_at).add(3, 'h').format('DD/MM/YYYY')}
                      </span>
                    </span>
                  </Col>

                  {
                    trainingPlanRequest.type !== 'ALTERNATIVE' &&
                    <Col lg={6}>
                      <span style={{ fontWeight: '550' }}>
                        Data do objetivo: <br />
                        <span style={{ fontWeight: '400' }}>
                          {trainingPlanRequest.version > 1 && moment(trainingPlanRequest.target_date).format('DD/MM/YYYY')}
                          {trainingPlanRequest.version === 1 && moment(trainingPlanRequest.target_date).add(3, 'h').format('DD/MM/YYYY')}
                        </span>
                      </span>
                    </Col>
                  }
                </Row>

                <br />
                <span style={{ fontWeight: '550', marginTop: 15 }}> Obs. sobre lesões: <br /><span style={{ fontWeight: '400' }}>{trainingPlanRequest.mhistory_notes ? trainingPlanRequest.mhistory_notes : '--'}</span></span> <br /><br />

                <span style={{ fontWeight: '550', marginTop: 15 }}> Obs. gerais: <br /><span style={{ fontWeight: '400' }}>{trainingPlanRequest.general_notes ? trainingPlanRequest.general_notes : '--'}</span></span>

                <br /><br />

                <span style={{ fontWeight: '550', marginTop: 15 }}> Recorde de distância nos últimos 30 dias: <br /><span style={{ fontWeight: '400' }}>{trainingPlanRequest.last_record ? `${trainingPlanRequest.last_record} km` : '--'}</span></span>

                <br />
                <br />

                <label htmlFor="internal_notes">Notas internas:</label>
                <br />
                <textarea
                  style={{ width: '100%' }}
                  rows="6"
                  name="internal_notes"
                  id="internal_notes"
                  value={internalNotes}
                  onChange={e => setInternalNotes(e.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <p style={{ fontWeight: 'bold', marginTop: 20 }}>Atribuir modelo de plano de treino</p>
                <div>
                  <Button color="secondary" outline id="toggler" style={{ marginBottom: '0rem', width: '100%' }}>Atribuir modelo</Button>
                  <UncontrolledCollapse toggler="#toggler">
                    <Card>
                      <CardBody style={{ padding: 0 }}>
                        <InputGroup style={{ margin: 5, width: '98%' }}>
                          <Input state={{ width: '90%' }} onChange={(e) => setSearchTrainingPlanQuery(e.target.value)} />
                          <InputGroupAddon addonType="append">
                            <Button color="info" onClick={() => searchTrainingPlans()}>pesquisar</Button>
                          </InputGroupAddon>
                        </InputGroup>

                        <PerfectScrollbar style={{ maxHeight: 200 }}>
                          <table className="table table-sm mb-0">
                            <tbody>

                              {trainingPlansList.map((tp, tpIdx) => {
                                return (
                                  <tr key={`idd_${tpIdx}`}>
                                    <td>
                                      <FormGroup check>
                                        <Label check>
                                          <Input type="checkbox" onClick={() => selectUser(tp.id)} />{' '} #{tp.id} - {tp.internal_name} ({tp.name})
                                        </Label>
                                      </FormGroup>
                                    </td>
                                  </tr>

                                )
                              })}
                            </tbody>
                          </table>
                        </PerfectScrollbar>

                        <hr style={{ borderColor: '#cccccc' }} />

                        <div style={{ display: 'flex', margin: 5, width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <b style={{ width: 100 }}>Iniciar em </b>
                            <Input type="date" size="sm" name="date" id="exampleDate" onChange={(e) => setTrainingPlanDate(e.target.value)} placeholder="date placeholder" style={{ marginLeft: 10 }} />
                          </div>

                          <div>
                            <Button color="secondary" size="sm" outline style={{ marginRight: 10 }} onClick={() => associateTPToUsers()}>Aplicar</Button>
                          </div>
                        </div>

                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <h3>Perfil Usuário </h3>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                {company ?
                  <h3>
                    {company && company.id === 1 ?
                      <span></span> :
                      <span className={` badge badge-danger`}> B2B</span>
                    }
                    {company && company.id === 1 ?
                      <span></span> :
                      <span className={` badge badge-danger`}> {company?.name}</span>
                    }

                  </h3>
                  : <></>
                }
                <h3>
                  {athletePlans.length ?
                    <span></span> :
                    <span className={` badge badge-danger`}> Primeiro Pedido</span>}
                  {!ownerCoach ?
                    <span></span> :
                    <span className={` badge badge-primary`}> {ownerCoach.name}</span>}
                  <span className={` badge badge-danger`}> {athlete?.subscription_type}
                  </span>
                </h3>
              </Col>
            </Row>
            <Row>
              <Col lg={5} style={{ marginTop: 10 }}>
                <span style={{ fontWeight: '450' }}>
                  {athlete?.name} (#{athlete?.id}) <br />
                  {athlete?.email}
                </span>
              </Col>
              <Col lg={4}>
                <Button color="secondary" onClick={() => handleUserDetailModal()}>Lista de atividades</Button>
              </Col>
              <Col lg={3}>
                <Button color="primary" onClick={() => {
                  const redirectURL = `/calendario?aID=${athlete.id}`
                  history.push(redirectURL)
                }}>Calendário</Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={3}><span style={{ fontWeight: '550' }}> Sexo: <span style={{ fontWeight: '400' }}>{athlete?.genre}</span></span></Col>
              <Col lg={3}><span style={{ fontWeight: '550' }}> Idade: <span style={{ fontWeight: '400' }}>{moment().diff(athlete?.birth, 'years', false)}</span></span></Col>
              <Col lg={3}><span style={{ fontWeight: '550' }}> Altura: <span style={{ fontWeight: '400' }}>{athlete?.height}</span></span></Col>
              <Col lg={3}><span style={{ fontWeight: '550' }}> Peso: <span style={{ fontWeight: '400' }}>{athlete?.weight}</span></span></Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={12}><span style={{ fontWeight: '550' }}>Dias base p/ corrida: <span style={{ fontWeight: '400' }}>{daysWeeklyWorkout}</span></span></Col>
              <Col lg={12} style={{ marginTop: 15 }}>
                <b>Desempenho:</b> <br /> <br />

                <span>5km: {trainingPlanRequest.km5_time ? formatDuration(trainingPlanRequest.km5_time, 'time') : '--'} em {trainingPlanRequest.km5_date ? moment(trainingPlanRequest.km5_date).format('DD/MM/YYYY') : '--'}</span> <br />
                <span>10km: {trainingPlanRequest.km10_time ? formatDuration(trainingPlanRequest.km10_time, 'time') : '--'} em {trainingPlanRequest.km10_date ? moment(trainingPlanRequest.km10_date).format('DD/MM/YYYY') : '--'}</span> <br />
                <span>21km: {trainingPlanRequest.km21_time ? formatDuration(trainingPlanRequest.km21_time, 'time') : '--'} em {trainingPlanRequest.km21_date ? moment(trainingPlanRequest.km21_date).format('DD/MM/YYYY') : '--'}</span> <br />
                <span>42km: {trainingPlanRequest.km42_time ? formatDuration(trainingPlanRequest.km42_time, 'time') : '--'} em {trainingPlanRequest.km42_date ? moment(trainingPlanRequest.km42_date).format('DD/MM/YYYY') : '--'}</span> <br />
              </Col>
              <Col lg={12} style={{ marginTop: 15 }}>
                <b>Testes de zonas mais recentes:</b> <br />
                {
                  zones.map(zone => (
                    <>
                      <span>{zone ? zone.pace : '--'} min/km {zone ? zone.bpm_avg : '--'} BPM em {zone && zone.execution_date ? moment(zone.execution_date).format('DD/MM/YYYY') : '--'}</span>
                      <br />
                    </>
                  ))
                }
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col>
                <b>Modelos de plano já atribuídos -  ({athletePlans.length}):</b>
                <Table size="sm" style={{ marginTop: 10 }}>
                  <thead>
                    <tr>
                      <th>Modelo</th>
                      <th>Autor</th>
                      <th>Início</th>
                      <th>Fim</th>
                    </tr>
                  </thead>
                  <tbody>
                    {athletePlans.map((aPlan, apIdx) => {
                      return (
                        <tr key={`ap_${apIdx}`}>
                          <th scope="row" style={{ textDecoration: aPlan?.status === 'DEACTIVATED' ? 'line-through' : 'none' }}>{aPlan.internal_name}</th>
                          <th scope="row" style={{ textDecoration: aPlan?.status === 'DEACTIVATED' ? 'line-through' : 'none' }}>{aPlan.coach.name}</th>
                          <td>{moment(aPlan.start_date).format('DD/MM/YYYY')}</td>
                          <td>{moment(aPlan.end_date).format('DD/MM/YYYY')}</td>
                          <td>
                            {aPlan?.status !== 'DEACTIVATED' &&
                              <Button onClick={() => deassociate(aPlan.id)} outline color="danger" style={{ display: 'flex', borderRadius: '50%', width: 30, height: 30, justifyContent: 'center', alignItems: 'center' }}>
                                <MAClose style={{ fontSize: 15 }} />
                              </Button>
                            }
                          </td>
                        </tr>
                      )
                    })}
                    <br />
                    <tr>
                      <Pagination
                        size="sm"
                        firstPageText="Início"
                        lastPageText="Fim"
                        previousPageText="Anterior"
                        nextPageText="Próximo"
                        totalItems={userTrainingPlansTotal}
                        pageSize={userTrainingPlansPerPage}
                        defaultActivePage={1}
                        onSelect={updateTrainingPlans}
                      />
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            Resp.:&nbsp;&nbsp;
            <Dropdown size="sm" isOpen={showDDCoach} toggle={() => setShowDDCoach(!showDDCoach)} >
              <DropdownToggle style={{ width: 250, marginRight: 10 }}> {currentCoach?.name} </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true, order: 890, fn: (data) => {
                      return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                    }
                  }
                }} >
                {coachsList.map((elem, index) => {
                  return (
                    <DropdownItem key={`co_${index}`} onClick={() => chooseCoach(elem)}>{elem.name}</DropdownItem>
                  )
                })}
              </DropdownMenu>
            </Dropdown>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            Status:&nbsp;&nbsp;
            <Dropdown size="sm" style={{}} isOpen={showDDStatus} toggle={() => setShowDDStatus(!showDDStatus)} >
              <DropdownToggle style={{ width: 150, marginRight: 10 }}> {currentStatus?.name} </DropdownToggle>
              <DropdownMenu
                modifiers={{
                  setMaxHeight: {
                    enabled: true, order: 890, fn: (data) => {
                      return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                    }
                  }
                }} >
                {statusList.map((elem, index) => {
                  return (
                    <DropdownItem key={`st_${index}`} onClick={() => chooseStatus(elem)}>{elem.name}</DropdownItem>
                  )
                })}
              </DropdownMenu>

            </Dropdown>

          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }} >
            <Button color="success" size="sm" style={{ marginLeft: 20, height: 30, width: 150, marginTop: 20 }} onClick={handleUpdate}>Atualizar</Button>
            <Button color="success" size="sm" style={{ marginLeft: 20, height: 30, width: 250, marginTop: 20 }} onClick={handleClick}> Atualizar e enviar email</Button>
          </div>

        </div>
      </ModalFooter>

      <TrainingPlanDetailsNotesModal loading={loading} show={showCoachNotesModal} toggle={() => setShowCoachNotesModal(show => !show)} currentCoach={currentCoach} currentStatus={currentStatus} internalNotes={internalNotes} updateRequest={updateRequest} />

      {showUserActivitiesModal && <UserActivitiesModal open={showUserActivitiesModal} user={athlete} toggle={handleUserDetailModal} />}
    </Modal>
  );
}
