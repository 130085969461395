import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Card, CardBody, Table, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Pagination from "react-reactstrap-pagination";
import moment from 'moment'
import { api2 } from '~/services/api'
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import { Container } from './styles'
import NewContentModal from './NewContentModal';
import { debounce } from 'lodash';

const types = [
    { name: 'Todos', id: 'all' },
    { name: 'Blog', id: 'BLOG' },
    { name: 'YouTube', id: 'YOUTUBE' },
    { name: 'Podcast', id: 'PODCAST' },
]

function ContentScreen() {
    const [loading, setLoading] = useState(false)
    const [contents, setContents] = useState([])
    const [newContentModal, setNewContentModal] = useState(false)
    const [editId, setEditId] = useState(null)

    const [selectedPage, setSelectedPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [perPage] = useState(50)
    const [search, setSearch] = useState('')
    const [showDropDownTypes, setShowDropDownTypes] = useState(false)
    const [selectedType, setSelectedType] = useState({ name: 'Todos', id: 'all' })

    const loadContent = useCallback(async (page, perPage = 50) => {
        try {
            setLoading(true)

            const skip = page > 1 ? (page - 1) * perPage : 0

            const { data } = await api2.get(`content?limit=${perPage}&skip=${skip}&title=${search}&type=${selectedType.id === 'all' ? '' : selectedType.id}`)

            setContents(data?.data)
            setTotalItems(data?.total)
            setSelectedPage(page)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Erro ao carregar os conteúdos')
        }
    }, [search, selectedType.id])

    const removeContent = async (id) => {
        let result = window.confirm("Tem certeza que deseja remover permanentemente esse conteúdo?");
        if (!result) return;
        api2.delete(`content/${id}`)
            .then(() => {
                loadContent(selectedPage);
                toast.success('Conteúdo removido com sucesso!');
            })
            .catch((err) => {
                console.log(err);
                toast.error('Erro ao remover o conteúdo')
            })
    }

    const openEditModal = (id) => {
        setEditId(id)
        setNewContentModal(true)
    }

    const closeModal = () => {
        setEditId(null)
        setNewContentModal(false)
    }

    useEffect(() => {
        loadContent(1)
    }, [loadContent])

    const searchDelayedQuery = useRef(
        debounce(e => {
            setSearch(e)
        }, 1000)
    ).current

    return (
        <Container>
            {newContentModal && <NewContentModal loadContent={loadContent} open={newContentModal} toggle={closeModal} editId={editId} />}

            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Conteúdos</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Card style={{ overflow: 'auto' }}>
                        <CardBody>
                            {loading && <Loader />}

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="card-body-header-title">
                                    <p>Total ({totalItems})</p>
                                    <p className="ml-2 btn btn-primary" onClick={() => setNewContentModal(true)}>Cadastrar novo conteúdo</p>
                                </div>

                                <div className="card-body-header-status mb-3">
                                    <div className="mr-2" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>Buscar</span>
                                        <input style={{ padding: 2 }} type="text" name="search" id="search" onChange={e => searchDelayedQuery(e.target.value)} placeholder="Título" />
                                    </div>

                                    <div className="card-body-header-status-dropdown">
                                        <span>Filtrar por tipo</span>

                                        <Dropdown size="sm" isOpen={showDropDownTypes} toggle={() => setShowDropDownTypes(prev => !prev)} >
                                            <DropdownToggle className="card-body-header-status-dropdown-toggle">{selectedType?.name}</DropdownToggle>

                                            <DropdownMenu
                                                modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true, order: 890, fn: (data) => {
                                                            return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, } }
                                                        }
                                                    }
                                                }}
                                            >
                                                {
                                                    types.map((elem, index) => {
                                                        return (
                                                            <DropdownItem
                                                                key={`ty_${index}`}
                                                                onClick={() => {
                                                                    setSelectedPage(1)
                                                                    setSelectedType(elem)
                                                                }}>{elem.name}</DropdownItem>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>

                            <Row>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Título</th>
                                            <th>Destaque</th>
                                            <th>Ordem</th>
                                            <th>Tipo</th>
                                            <th>URL</th>
                                            <th>Data</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contents.length > 0 && contents.map((content, index) => (
                                            <tr key={content._id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{content.title}</td>
                                                <td>{content.highlight ? 'Sim' : 'Não'}</td>
                                                <td>{content.order ?? 0}</td>
                                                <td>{content.type}</td>
                                                <td><a href={content.action_url}>Link</a></td>
                                                <td>{content.created_at ? moment(content.created_at).format('DD/MM/YYYY') : '-'}</td>
                                                <td className="tabledit-view-mode">
                                                    <Button color="primary" onClick={() => openEditModal(content._id)}>Editar</Button>
                                                </td>
                                                <td className="tabledit-view-mode">
                                                    <Button color="danger" onClick={() => removeContent(content._id)}>Excluir</Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs={12} style={{ overflow: 'auto' }}>
                    <Pagination
                        firstPageText="Início"
                        lastPageText="Fim"
                        previousPageText="Anterior"
                        nextPageText="Próximo"
                        totalItems={totalItems}
                        pageSize={perPage}
                        defaultActivePage={selectedPage}
                        onSelect={loadContent}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default ContentScreen;